@use '@fixed-size' as *;
@use '@typography' as *;
@use '@queries' as *;

.container {
  &-mobile {
    width: 100%;
    height: 38px;

    @include min-428-break {
      height: 48px;
    }
  }

  &-desktop {
    position: relative;
    @include fixedSize(170px, 38px);

    @include min-1440-break {
      @include fixedSize(224px, 44px);
    }
  }

  &-header {
    all: unset;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 10px 12px;
    cursor: pointer;
    box-shadow: inset 0 0 0 2px var(--cst-drop-listsort-outline-default);
    background: var(--cst-drop-listsort-background-default);
    border-radius: var(--cornerradius10);
    height: inherit;
    width: 100%;
    transition: all 0.2s ease-in-out;
    &:hover {
      box-shadow: inset 0 0 0 2px var(--cst-drop-listsort-outline-hover);
      background: var(--cst-drop-listsort-background-hover);
      &--selected-item {
        .info {
          color: var(--cst-drop-listsort-text-hover);
        }
      }

      .arrow-icon {
        color: var(--cst-drop-listsort-icon-hover);
      }
    }

    &:focus-visible {
      box-shadow: inset 0 0 0 2px var(--cst-drop-listsort-outline-focus);
      background: var(--cst-drop-listsort-background-focus);
      &--selected-item {
        .info {
          color: var(--cst-drop-listsort-text-focus);
        }
      }

      .arrow-icon {
        color: var(--cst-drop-listsort-icon-focus);
      }
    }

    &--selected-item {
      display: flex;
      align-items: center;
      gap: 8px;
      @include min-1024-break {
        gap: 12px;
      }

      .info {
        color: var(--cst-drop-listsort-text-default);
        @include typography-s;
        @include weight-medium;

        @include min-428-break {
          @include typography-base;
        }

        @include min-1024-break {
          @include typography-s;
        }

        @include min-1440-break {
          @include typography-m;
          @include weight-medium;
        }
      }
    }

    &-opened {
      pointer-events: none;
    }
  }
  &-content {
    display: flex;
    flex-direction: column;
    gap: 12px;

    @include min-428-break {
      gap: 14px;
    }
  }
}

.items-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
  @include min-428-break {
    gap: 10px;
  }
  @include min-744-break {
    width: 400px;
  }
}

.drop_down {
  width: 100%;
  margin-top: 8px;
  display: flex;
  flex-direction: column;
  gap: 4px;
  padding: 4px;
  border-radius: var(--cornerradius10);
  box-shadow: inset 0 0 0 2px var(--cst-drop-listsort-outline-default);
  background: var(--cst-drop-listsort-backgroundopen-default);

  @include min-1440-break {
    margin-top: 10px;
  }

  &-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 8px 10px;

    @include min-428-break {
      min-height: 44px;
      padding: 11px 10px;
    }

    @include min-744-break {
      width: 400px;
    }

    @include min-1024-break {
      padding: 10px;
    }
    border-radius: var(--cornerradius10);
    background: #fff;

    p[id='list-item-title'] {
      color: var(--cst-dropitem-text-default);
      @include weight-medium;
      @include typography-s;

      @include min-744-break {
        @include typography-base;
      }

      @include min-1024-break {
        @include typography-s;
      }

      @include min-1440-break {
        @include typography-m;
      }
    }

    @include fixedSize(100%, 38px);

    @include min-1440-break {
      @include fixedSize(100%, 44px);
    }
  }
}

.arrow-icon {
  transition: all 0.2s;
  color: var(--cst-drop-listsort-icon-default);
  @include singleFixedSize(22px);
  &-active {
    transform: rotate(180deg);
  }

  @include min-1024-break {
    @include singleFixedSize(18px);
  }

  @include min-1440-break {
    @include singleFixedSize(22px);
  }
}

.item-icon {
  @include singleFixedSize(22px);

  @include min-1024-break {
    @include singleFixedSize(18px);
  }

  @include min-1440-break {
    @include singleFixedSize(22px);
  }
}

.modal {
}
